/*----------------------------------------------------------------------------*
	$FORMULARIOS
*----------------------------------------------------------------------------*/



/* ^Select
-----------------------------------------------------------------------------*/

	// Todos los select
	$('.js-select').selectize();

/* ^Fin - Select
-----------------------------------------------------------------------------*/





/* ^Login
-----------------------------------------------------------------------------*/

	var form_login = $("#form-login");

	// Validar campos del formulario Login
	form_login.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-login',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.c-form-login__error'));
		},
		rules: {
			login__email: {
				required: true,
				email: true
			},
			login__password: "required"
		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-login');

			// Datos formulario
			var datos = form_login.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/login/logueo/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notification-login').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Si se logueó correctamente
						if(data.match('1') == '1'){

							window.location.href = '/';

						} else {

							setTimeout(function(){
								$('.js-notification-login').slideDown(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Login
-----------------------------------------------------------------------------*/





/* ^Registro
-----------------------------------------------------------------------------*/

	var form_registro = $("#form-registro");

	// Validar campos del formulario Login
	form_registro.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-login',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.c-form-login__error'));
		},
		rules: {
			registro__email: {
				required: true,
				email: true
			},
			registro__nombre: "required",
			registro__password: "required",
			registro__password2: {
				required: true,
				equalTo: "#password"
			}
		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-registro');

			// Datos formulario
			var datos = form_registro.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/login/register/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notification-login').slideUp(400);
			$('.js-notification-existe').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Se registró exitosamente
						if(data.match('1') == '1'){

							setTimeout(function(){
								$('.js-notification-login').slideDown(400);

								$('.js-box-form-login__box').slideUp(400);
								$('.js-notification-existe').slideUp(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						} else if(data.match('existe') == 'existe') {

							// console.log('usuario ya existe!');

							setTimeout(function(){
								$('.js-notification-existe').slideDown(400);

								$('.js-notification-login').slideUp(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Registro
-----------------------------------------------------------------------------*/





/* ^Recuperar password
-----------------------------------------------------------------------------*/

	var form_recuperar = $("#form-recuperar-password");

	// Validar campos del formulario Login
	form_recuperar.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-login',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.c-form-login__error'));
		},
		rules: {
			recuperar__email: {
				required: true,
				email: true
			}
		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-recuperar');

			// Datos formulario
			var datos = form_recuperar.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/login/recover/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notification-login').slideUp(400);
			$('.js-notification-existe').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Se recupero la contraseña exitosamente
						if(data.match('1') == '1'){

							setTimeout(function(){
								$('.js-notification-login').slideDown(400);

								$('.js-box-form-login__box').slideUp(400);
								$('.js-notification-existe').slideUp(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						} else if(data.match('noexiste') == 'noexiste') {

							// console.log('usuario ya existe!');

							setTimeout(function(){
								$('.js-notification-existe').slideDown(400);

								$('.js-notification-login').slideUp(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Recuperar password
-----------------------------------------------------------------------------*/





/* ^Nueva Contraseña
-----------------------------------------------------------------------------*/

	var form_new_password = $("#form-new-password");

	// Validar campos del formulario Login
	form_new_password.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-login',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.c-form-login__error'));
		},
		rules: {
			new__password: "required",
			new__password2: {
				required: true,
				equalTo: "#password"
			}
		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-recuperar');

			// Datos formulario
			var datos = form_new_password.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/login/nuevo-password/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notification-login').slideUp(400);
			$('.js-notification-existe').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Se se cambió la contraseña exiosamente
						if(data.match('1') == '1'){

							window.location.href = '/login/cambio-pass/';

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Nueva Contraseña
-----------------------------------------------------------------------------*/





/* ^Editar Perfil
-----------------------------------------------------------------------------*/

	var form_editar_perfil = $("#form-editar-perfil");

	// Boton
	var boton_enviar = $('.js-btn-guardar-perfil');



	/**
	 *
	 * Cargador de archivos
	 *
	 */
	$('#upload_archivo1').uploadifive({
		'multi'				: false,
		// 'queueSizeLimit' 	: 1,
		'width'				: 'auto',
		'height'			: '40px',
		'removeCompleted'	: false,
		'truncateLength'	: 20,
		'buttonClass'		: 'icon-upload6  o-btn--upload',
		'fileSizeLimit'		: 5120,
		'fileType'     		: 'image/*',
		'buttonText'		: 'Cargar foto',
		'auto'				: false,
		'queueID'			: 'queue',
		'formData'			: {
			'usuario_id'	: $('#usuario-id').val()
		},
		'uploadScript'		: '/perfil/uploadphoto/',
		'onQueueComplete'	: function(uploads) {

			// console.log("Cargado  : " + file.name);
			// console.log("Cargado 1: ");

			$.get('/perfil/get-photo/', function(data){
				$('.js-user-photo').attr('src', data);
				// console.log(data);
			});

			// Ocultar cargador
			$('.js-menu-mobile-overlay').fadeOut(200);
			$('.js-loader').fadeOut(400);

			boton_enviar.removeAttr('disabled');

			// Mostrar mensaje de exito al enviar formulario
			$('.js-notificacion').slideDown(400, function(){
				setTimeout(function(){
					$('.js-notificacion').slideUp(400);
				},4000);
			});

			// Borrar todas las cargas de archivos que tengan error
			$('#upload_archivo1').uploadifive('clearQueue');

		},
		'onAddQueueItem': function(file){

			$(this).uploadifive('clearQueue');

			$('[name="upload_archivo1_peso"]').val('1');
			form_editar_perfil.validate().element('.js-validar-peso');

		},
		'onError': function(errorType) {

			if( errorType == 'FILE_SIZE_LIMIT_EXCEEDED' ) {
				$('[name="upload_archivo1_peso"]').val('');
				form_editar_perfil.validate().element('.js-validar-peso');
			}

        },
		'onCancel': function() {
			$('[name="upload_archivo1_peso"]').val('1');
			form_editar_perfil.validate().element('.js-validar-peso');
        }

	});


	// Validar campos de registro de evento
	form_editar_perfil.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-contact',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.js-campo'));
		},
		rules: {
			perfil__nombres: "required",
			perfil__apellidos: "required",
			perfil__email: {
				required: true,
				email: true
			},
			upload_archivo1_peso: "required"
		},
		messages: {
			upload_archivo1_peso: "El archivo tiene un peso mayor al permitido de 5MB."
		},
		submitHandler: function(form) {

			// Datos formulario
			var datos = form_editar_perfil.serialize();

			// Action del formulario
			var action_enviar = '/perfil/update-profile/';


			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notificacion').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').fadeIn(200);
			$('.js-loader').fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						// console.log(data);
						// Si se envió correctamente
						if(data.match('1') == '1'){

							// Validación de archivo 1
							if ($("#queue > div").size() == 0) {

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200);
								$('.js-loader').fadeOut(400);

								boton_enviar.removeAttr('disabled');

								// Mostrar mensaje de exito al enviar formulario
								$('.js-notificacion').slideDown(400, function(){
									setTimeout(function(){
										$('.js-notificacion').slideUp(400);
									},4000);
								});

								// Borrar todas las cargas de archivos que tengan error
								$('#upload_archivo1').uploadifive('clearQueue');

							} else {
								$('#upload_archivo1').uploadifive('upload');
							}

						}

					}
			);
			// Fin - $.post()



		},
		invalidHandler: function() {
			// $( "#errores" ).text( validator.numberOfInvalids() + " field(s) are invalid" );

			// console.log("erroe");
		}
	});


/* ^Fin - Editar Perfil
-----------------------------------------------------------------------------*/





/* ^Editar contraseña
-----------------------------------------------------------------------------*/

	var form_editar_pass = $("#form-editar-password");

	// Validar campos del formulario Login
	form_editar_pass.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-contact',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.js-campo'));
		},
		rules: {
			pass__viejo: "required",
			pass__nuevo: "required",
			pass__nuevo2: {
				required: true,
				equalTo: "#password"
			}
		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-guardar-password');

			// Datos formulario
			var datos = form_editar_pass.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/perfil/update-password/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notificacion-error').slideUp(400);
			$('.js-notificacion').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Cambio de contraseña exitoso
						if(data.match('1') == '1'){

							setTimeout(function(){
								$('.js-notificacion').slideDown(400, function(){
									setTimeout(function(){
										$('.js-notificacion').slideUp(400);
									},4000);
								});
								$('.js-notificacion-error').slideUp(400);

								$('.o-form-field').val('');

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						} else {

							setTimeout(function(){
								$('.js-notificacion-error').slideDown(400);

								$('.js-notificacion').slideUp(400);

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Editar contraseña
-----------------------------------------------------------------------------*/





/* ^Editar reembolso
-----------------------------------------------------------------------------*/

	/**
	 *
	 * Validar check para mostrar/ocultar campos del formulario
	 *
	 */
	$('.js-check-reembolso').on( 'change', function() {

		var campo = $(this),
			campo_val = campo.val(),
			campo_text = campo.next('label').children('span').text();


		// mostrar/ocultar campos
		if(campo_val == 'Giro Internacional'){

			$('#giro').slideDown(400);
			$('#paypal').slideUp(400);
		}

		if(campo_val == 'PayPal'){

			$('#giro').slideUp(400);
			$('#paypal').slideDown(400);
		}


	});


	var form_editar_reembolso = $("#form-reembolso");

	// Validar campos del formulario Login
	form_editar_reembolso.validate({
		ignore: ".ignore",
		errorClass: 'o-form-error-contact',
		errorPlacement: function(error, element) {
			error.appendTo(element.parents('.js-campo'));
		},
		rules: {

		},
		submitHandler: function(form) {

			// Boton
			var boton_enviar = $('.js-btn-guardar-reembolso');

			// Datos formulario
			var datos = form_editar_reembolso.serialize();

			// Action del formulario
			// Controller/Metod/
			var action_enviar = '/perfil/update-reembolso/';

			// Desabilito el boton enviar para evitar doble envio
			boton_enviar.attr("disabled",true);

			// Ocultar las notificaciones al presionar boton enviar
			$('.js-notificacion').slideUp(400);

			// Mostrar cargador
			$('.js-menu-mobile-overlay').css('z-index',5000).fadeIn(200);
			$('.js-loader').css('z-index',5001).fadeIn(400);


			$.post(action_enviar, datos,
				function(data)
					{

						boton_enviar.removeAttr('disabled');

						// console.log(data);

						// Cambio de contraseña exitoso
						if(data.match('1') == '1'){

							setTimeout(function(){
								$('.js-notificacion').slideDown(400, function(){
									setTimeout(function(){
										$('.js-notificacion').slideUp(400);
									},4000);
								});

								// Ocultar cargador
								$('.js-menu-mobile-overlay').fadeOut(200, function(){
									$(this).css('z-index',100)
								});
								$('.js-loader').fadeOut(400, function(){
									$(this).css('z-index',101)
								});
							},1000);

						}


					}
			);
			// Fin - $.post()


		}
	});

/* ^Fin - Editar reembolso
-----------------------------------------------------------------------------*/
