/*----------------------------------------------------------------------------*
	$SLIDERS.js
*----------------------------------------------------------------------------*/


/* ^Slider Home
-----------------------------------------------------------------------------*/

	$('.js-slider-login').slick({
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 1500,
		infinite: true,
		dots: false,
		fade: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		touchMove: false,
		draggable: false,
		accessibility: false,
		arrows: false,
		// adaptiveHeight: true,
		easing: 'easeInOutQuart',
		prevArrow: '<div class="slick-prev  slick-arrow  icon-arrow-left3"></div>',
		nextArrow: '<div class="slick-next  slick-arrow  icon-arrow-right3"></div>',
		responsive: [
			{
				breakpoint: 991,
				settings: {
					easing: 'easeOutCubic',
				}
			},
		]
	});


/* ^Fin - Slider Home
-----------------------------------------------------------------------------*/






/* ^Slider 1
-----------------------------------------------------------------------------*/

	$('.js-slider-1').slick({
		infinite: true,
		dots: true,
		fade: false,
		arrows: false,
		// adaptiveHeight: true,
		easing: 'easeInOutQuart',
		prevArrow: '<div class="slick-prev  slick-arrow  icon-arrow-left3"></div>',
		nextArrow: '<div class="slick-next  slick-arrow  icon-arrow-right3"></div>',
		responsive: [
			{
				breakpoint: 991,
				settings: {
					easing: 'easeOutCubic',
				}
			},
		]
	});

/* ^Fin - Slider 1
-----------------------------------------------------------------------------*/
