$(document).on('turbolinks:load', function() {

	Hyphenator.run();

	//=require includes/turbolinks.js

	//=require includes/menus.js

	//=require includes/formularios.js

	//=XXXrequire includes/images.js

	//=XXXrequire includes/menus.js

	//=XXXrequire includes/scroll-infinito.js

	//=require includes/popups.js

	//=require includes/sliders.js

	//=XXXrequire includes/noticias.js

	//=XXXrequire includes/calendarios.js

	//=XXXrequire includes/rutas.js


});
