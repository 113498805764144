/*----------------------------------------------------------------------------*
	$MENUS.js
*----------------------------------------------------------------------------*/


/*	^Detectar ancho de scroll
-------------------------------------------------------------*/

	var scrollDiv = document.createElement("div");
	scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';

	document.body.appendChild(scrollDiv);

	scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);


/*	^Fin - Detectar ancho de scroll
-------------------------------------------------------------*/





/* ^Perfect Scrollbar
-----------------------------------------------------------------------------*/

	if( $('.js-scrollbar').length ){
		var ps = new PerfectScrollbar('.js-scrollbar');
	}

/* ^Fin - Perfect Scrollbar
-----------------------------------------------------------------------------*/





/* ^Copiar texto con boton
-----------------------------------------------------------------------------*/

	$('.js-btn-copy').on('click', function(e) {
		e.preventDefault();

		var element = $(this).attr('data-element');

		var $temp = $("<input>");
		$("body").append($temp);
		$temp.val($(element).text()).select();
		document.execCommand("copy");
		$temp.remove();
	})

/* ^Fin - Copiar texto con boton
-----------------------------------------------------------------------------*/





/*	^Submenu menú principal
-----------------------------------------------------------------------------*/

	$('.js-submenu').on('click', function(e){
		e.preventDefault();

		$('.js-submenu').not(this).next().slideUp(400);
		$('.js-submenu').not(this).removeClass('open');
		$('.js-submenu').not(this).children('i').removeClass('active');

		if( $(this).children('i').hasClass('active') ) {

			$(this).children('i').removeClass('active');
			$(this).removeClass('open');

		} else {

			$(this).children('i').addClass('active');
			$(this).addClass('open');

		}

		$(this).next().slideToggle(400);

	});


	// Abrir por defecto el submenu
	if( $('.js-open-submenu').length ) {

		$('.js-open-submenu > a').addClass('open');

		$('.js-open-submenu > a i').addClass('active');

		$('.js-open-submenu .c-nav-1__submenu').css('display', 'block');

	}



/*	^Fin - Submenu menú principal
-----------------------------------------------------------------------------*/





/* ^Completar perfil
-----------------------------------------------------------------------------*/

	// Cerrar componente completa tu perfil
	$('.js-btn-completar-despues').on('click', function(e) {
		e.preventDefault();

		$('#completar-perfil').slideUp(400, function() {

			// actualizar DB para completar perfil después
			$.get(
				'/perfil/completeprofile/',
				{
					status: 1
				}
			);
			// /$.get()

		})
	})

/* ^Fin - Completar perfil
-----------------------------------------------------------------------------*/





/* ^Cerrar y abrir columna 1
-----------------------------------------------------------------------------*/

	// versión escritorio
	$('.js-btn-close-col-1').on('click', function(e) {
		e.preventDefault();

		var action_enviar = '/perfil/update-open-menu/';

		if( $('#container-global').hasClass('c-block-2--close-menu') ){
			$('#container-global').removeClass('c-block-2--close-menu');
			$('.js-btn-close-col-1').removeClass('c-block-header__nav--close');

			$.post(action_enviar,
				{
					open_menu: 1
				},
				function(data)
					{
						if(data.match('1') == '1'){

							// console.log('cerrado');

						}
					}
			);
			// Fin - $.post()

		}
		else {
			$('#container-global').addClass('c-block-2--close-menu');
			$('.js-btn-close-col-1').addClass('c-block-header__nav--close');

			$.post(action_enviar,
				{
					open_menu: 0
				},
				function(data)
					{
						if(data.match('1') == '1'){

							// console.log('cerrado');

						}
					}
			);
			// Fin - $.post()

		}

	});


	// versión movil
	$('.js-btn-open-movil').on('click', function(e) {
		e.preventDefault();

		if( $('#container-global').hasClass('c-block-2--open-menu') ){
			$('#container-global').removeClass('c-block-2--open-menu');
			$('.js-btn-open-movil').removeClass('c-block-header__nav--close-2');
		}
		else {
			$('#container-global').addClass('c-block-2--open-menu');
			$('.js-btn-open-movil').addClass('c-block-header__nav--close-2');
		}

	});

/* ^Fin - Cerrar y abrir columna 1
-----------------------------------------------------------------------------*/






/*	^Accordeon
-----------------------------------------------------------------------------*/

	$('.js-accordeon').on('click', function(e){
		// e.stopPropagation();
		e.preventDefault();

		// $('.js-accordeon').not(this).next().slideUp(400);

		$(this).find('.c-list-7__info').slideToggle(400);

		$('.js-accordeon').not(this).find('.c-list-7__info').slideUp(400);


		if( $(this).hasClass('active') ) {
			$(this).find('i').removeClass('icon-circle-up2');
			$(this).find('i').addClass('icon-circle-down2');
		} else {
			$(this).find('i').removeClass('icon-circle-down2');
			$(this).find('i').addClass('icon-circle-up2');
		}

		$('.js-accordeon').not(this).removeClass('active');
		$(this).toggleClass('active');

		$('.js-accordeon').not(this).find('i').removeClass('icon-circle-up2');
		$('.js-accordeon').not(this).find('i').addClass('icon-circle-down2');


		// $(this).next().slideToggle(400);

	});

	$('.c-list-7__info-box').on('click', function(e){
		e.stopPropagation();
		// e.preventDefault();
	});

	$('.js-accordeon:first-child').trigger('click');

/*	^Fin - Accordeon
-----------------------------------------------------------------------------*/
